import React from 'react';
import SEO from "../components/seo/Seo";
import Header from "../components/headers/Header";
import Footer from "../components/layouts/Footer";
import CompanyInfo from "../components/info/CompanyInfo";
import ContactHeader from "../components/info/ContactHeader";

function KontaktInInformacije(props) {
    return (
        <>
            <SEO title="Kontakt in informacije"
                 description="Turistično informacijski center (TIC) nudi informacije o turistični ponudbi v Litiji in okolici. TIC je vstopna točka za rudnik Sitarjevec Litija LINK. Rudnik lahko obiščete po predhodni najavi od četrtka do nedelje, večje skupine po dogovoru. TIC organizira turistične izlete za prihajajoče goste. V TIC Litija deluje z lokalnimi produkti lepo založena trgovinica, ki so primerni tudi kot spominki ali simbolična darila. Izbirate lahko med unikatnimi lesenimi, keramičnimi, pletarskim, tekstilnimi izdelki, različnim nakitom, naravnimi mili, čaji in drugimi unikatnimi rokodelskimi izdelki."
            />
            <Header />
            <ContactHeader/>
            <CompanyInfo />
            <Footer/>
        </>
    );
}

export default KontaktInInformacije;