import React from 'react';
import {StaticImage} from "gatsby-plugin-image";

function MuseumBox(props) {
    return (
        <div>
            <div className="rn-address bg_color--3">
                <div className="inner d-flex justify-content-center align-items-center flex-column text-center">
                    <div className="px-4 py-1">
                        <a href="//www.muzejlitija.si/" target="_blank">
                            <StaticImage
                                src="../../assets/images/rudnik/mestni-muzej-litija-logo.png"
                                alt="Mestni Muzej Litija"
                                placeholder="blurred"
                                imgClassName="img-fluid"
                                loading="lazy"
                            />
                        </a>
                    </div>

                    <h4 className="title text-center d-inline-block mt--15">Mestni muzej Litija</h4>
                    <p>Cesta komandanta Staneta 2, 1270 Litija</p>

                    <p className="mt--15">
                        <a href="mailto:mestnimuzej@zkms-litija.si">mestnimuzej@zkms-litija.si</a>
                    </p>

                    <p><a href="tel:+38631689160">031 689 160</a></p>
                    <p><a href="tel:+38670430202">070 430 202</a></p>

                </div>
            </div>
        </div>
    );
}

export default MuseumBox;