import React from 'react';
import {StaticImage} from "gatsby-plugin-image";

function ZkmsInfoBox(props) {
    return (
        <div className="rn-address bg_color--3 mb-5">
            <div className="inner d-flex justify-content-center align-items-center flex-column text-center">
                <div className="px-2 py-1">
                    <StaticImage
                        src="../../assets/images/rudnik/zkms-logo.png"
                        alt="ZKMS logo"
                        placeholder="blurred"
                        imgClassName="img-fluid"
                        loading="lazy"
                    />
                </div>

                <h4 className="title d-inline-block mt--15">Javni zavod za kulturo, mladino in šport
                    Litija</h4>
                <p>Trg na stavbah 8a, 1270 Litija</p>
                <p>DŠ: 13109154</p>
                <p>MŠ: 63018190000</p>
                <p>TRR: SI56 0110 0600 0069 475</p>
                <p className="mt--15">
                    <a href="https://zkms-litija.si" target="_blank">Spletna stran</a>
                </p>
            </div>
        </div>
    );
}

export default ZkmsInfoBox;