import React from 'react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const mapOptions={};

function MineGoogleMap({address, googleApiKey}) {

    const lat = parseFloat(address.lat);
    const lng = parseFloat(address.lng);

    return (
        <div style={{ height: '400px', width: '100%'}}>

            <GoogleMapReact
                bootstrapURLKeys={{ key: googleApiKey }}
                defaultCenter={[lat, lng]}
                defaultZoom={14}
            >
                <AnyReactComponent
                    text="TIC LITIJA"
                    lat={46.056522056071394}
                    lng={14.83124147640337}
                />

                <AnyReactComponent
                    text="Rudnik Sitarjevec"
                    lat={46.049468464716234}
                    lng={14.830703183060104}
                />
            </GoogleMapReact>
        </div>
    );
}

export default MineGoogleMap;